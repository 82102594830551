import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {
  Box,
  Tabs,
  Tab,
  Tooltip,
  Button,
  Modal,
  TextField,
  FormGroup,
  Typography,
  Switch,
  FormControlLabel,
  Snackbar,
  Alert,
  Card,
  CardHeader,
} from "@mui/material"
import MUIDataTable from "mui-datatables"
import { CheckCircle, AlertOctagon, AlertTriangle, PlusCircle } from "react-feather"
import { useForm } from "react-hook-form"
import CenteredSpinner from "../../organisms/CenteredSpinner";

import FCSpokesAPI from "../../utils/fetch/spokes/spokes";

import {
  setActivePage
} from '../../reducers/appSettingsSlice';

import {
  setInitServerData,
  updateItemStatus,
  setSyncingStatus,
} from "../../reducers/serverMonitorSlice";

export const ServerMonitor = () => {

  /*Had to duplicate the modalStyle from the theme as its not importable from the theme file for some reason*/
  /*TODO: fix the bug preventing import of the modalStyles here */
  /*TODO: fix the same bug that requires duplicating the 'CenteredSpinner' element*/
  const localModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 390,
    bgcolor: '#333333',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    color: "#fff",
    zIndex: 100,
    overflowX: "auto",
    maxHeight: "95%"
  }

  // API init and React dispatch
  const spokesAPI = new FCSpokesAPI();
  const dispatch = useDispatch();

  // react-hook-form methods
  const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();

  //
  const ActivePage = useSelector((state) => state.AppSettings.ActivePage);
  const ClientData = useSelector((state) => state.ServerMonitor.data);
  const userSessionPermissions = useSelector((state) => state.UserSessions.permissions);
  const filterString = useSelector((state) => state.ServerMonitor.filterString);
  const isStatusSyncing = useSelector((state) => state.ServerMonitor.syncing);

  // general page state
  const [locations, setLocations] = useState([])
  const [archivedLocations, setArchivedLocations] = useState([]);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false)
  const [editData, setEditData] = useState({})
  const [editID, setEditID] = useState(0)
  const [activeTab, setActiveTab] = useState(0);
  const [buttonLoading,setButtonLoading] = useState({syncButton: false,});

  // page snackbar state
  const [createSuccessSnackBar, setCreateSuccessSnackBar] = useState(false)
  const [editSuccessSnackBar, setEditSuccessSnackBar] = useState(false)
  const [errorSnackBar, setErrorSnackBar] = useState(false)

  const forceUpdate = async () => {
    if (isStatusSyncing) {
      return
    };
    const synctime = new Date().getTime() / 1000;
    dispatch(setSyncingStatus({ syncing: true, syncTime: synctime}));

    const locationData = await spokesAPI.getSpokes();
    const statusData = await spokesAPI.getSpokesAndStatus();

    const newState = Object.assign({}, ClientData);
    newState.locations = [...locationData];
    newState.initialData = statusData;
    newState.locationsMap = {};
    newState.filterString = filterString;
    locationData.forEach((loc) => {
      newState.locationsMap[loc.location_id] = loc.shortName;
    });
    setLocations(statusData)
    dispatch(setInitServerData(newState));
    dispatch(setSyncingStatus({ syncing: false, syncTime: synctime }));
  };

  const getServerData = async () => {
    const synctime = new Date().getTime() / 1000;

    dispatch(setSyncingStatus({ syncing: true, syncTime: synctime }));
    const locations = await spokesAPI.getSpokes();
    const statusData = await spokesAPI.getSpokesAndStatus();

    const newState = {};
    newState.locations = locations;
    newState.initialData = statusData;
    newState.filterString = filterString;
    newState.locationsMap = {};
    locations.forEach((loc) => {
      newState.locationsMap[loc.location_id] = loc.short_name;
    });
    /*The following line sets the non-archived server data*/
    setLocations(statusData)
    dispatch(setInitServerData(newState));
    dispatch(setSyncingStatus({ syncing: false, syncTime: synctime }));
  };

  useEffect(() => {
    if (ActivePage !== "server_monitor") {
      dispatch(setActivePage("server_monitor"));
    }
  });

  const getArchivedLocations = async () => {
    // getter2
    const res = await spokesAPI.getArchivedSpokes();
    setArchivedLocations(res);
  };

  const getServiceDisplayString = (value) => {
    switch(value){
      case null: {
        return <Tooltip title="Unknown Status"><AlertTriangle color={"#FD6910"}/></Tooltip>
      }
      case false: {
        return <Tooltip title="Unavailable"><AlertOctagon color={"#CA111E"}/></Tooltip>
      }
      case true: {
        return <CheckCircle color={"#2e7d32"}/>
      }
      case undefined: {
        return <AlertTriangle color={"#FD6910"}/>
      }
      default: {
        return ""
      }
    }

  }

  const activeServerColumns = [
    {name: "shortName", label: "Location", options: {sort: true, filter: true}},
    {name: "closStatus", label: "CLOS", options: {
      sort: true,
      filter: true,
      customBodyRenderLite: (dataIndex) => {
        return getServiceDisplayString(locations[dataIndex].closStatus)
      }}},
    {name: "mysqlStatus", label: "MySQL", options: {
      sort: true,
      filter: true,
      customBodyRenderLite: (dataIndex) => {
        return getServiceDisplayString(locations[dataIndex].mysqlStatus)
      }}},
    {name: "sybaseStatus", label: "Sybase", options: {
      sort: true,
      filter: true,
      customBodyRenderLite: (dataIndex) => {
        return getServiceDisplayString(locations[dataIndex].sybaseStatus)
      }}},
    {name: "nodeStatus", label: "Spoke Status", options: {
      sort: true,
      filter: true,
      customBodyRenderLite: (dataIndex) => {
        return getServiceDisplayString(locations[dataIndex].nodeStatus)
      }}},
    {name: "mins_since_last_sync", label: "Last Sync", options: {
      sort: true,
      filter: true,
      customBodyRenderLite: (dataIndex) => {
        const minutes = locations[dataIndex].mins_since_last_sync
        if ( minutes !== null && minutes !== undefined) {
          let calcHours = Math.round(minutes/60)
          if (calcHours < 1){
            return `${minutes} minutes ago`
          } else if (calcHours < 48 && calcHours > 1 ){
            return `${calcHours} hours ago`
          } else if (calcHours > 48) {
            return "More than two days ago"
          }
        } else {
          return " - "
      }
      }}},
    {name: "syncEnabled", label: "Sync Enabled", options: {
      sort: true,
      filter: false,
      customBodyRenderLite: (dataIndex) => {
        const opposingText = (locations[dataIndex].syncEnabled === true) ? "Disable" : "Enable"
        return <Button
                variant={"contained"}
                sx={{backgroundColor: (opposingText === "Enable") ? "#212121" : "#CA111E", color: "#fff", fontSize: "12px"}}
                onClick={() => {
                  toggleLocationSync(locations[dataIndex], opposingText.toLowerCase());
                }}>{opposingText}
              </Button>
      }}},
    {name: "killSwitchEngaged", label: "Kill Switch", options: {
      sort: true,
      filter: false,
      customBodyRenderLite: (dataIndex) => {
        const row = locations[dataIndex]
        const opposingText = (row.killSwitchEngaged === true) ? "Disable" : "Enable"
        return <Button
                variant={"contained"}
                sx={{backgroundColor: opposingText === "Enable" ? "#212121" : "#CA111E", color: "#fff", fontSize: "12px"}}
                onClick={() => {
                  toggleKillSwitch(row, opposingText.toLowerCase())
                }}>{opposingText}</Button>
      }}},
    {name: "Edit", label: "Edit", options: {sort: false, filter: false, customBodyRenderLite: (dataIndex) =>{
      return <Button
              variant={"contained"}
              sx={{backgroundColor: "#212121", color: "#fff", fontSize: "12px"}}
              onClick={() =>{
                openEditModal(locations[dataIndex])
              }}>Edit</Button>
        }}},
  ]

  const archivedServerColumns = [
    {name: "id", label: "ID", options: {sort: true, filter: true}},
    {name: "shortName", label: "Name", options: {sort: true, filter: true}},
    {name: "updated", label: "Archive Date", options: {
      sort: true,
      filter: true,
      customBodyRenderLite: (dataIndex) => {
        return moment(archivedLocations[dataIndex].updated).format("MM/d/YYYY")
      }
    }},
  ]

  const toggleKillSwitch = (server, direction) => {
    if (direction === "enable"){
        if (window.confirm(`Are you sure you want to engage the killswitch at ${server.shortName}?`)){
          spokesAPI.engageKillswitch(server.shortName).then(() =>{
            forceUpdate()
            alert("Please allow up to 30 seconds for changes to be reflected.");
          }
        )
      }
    } else if (direction === "disable"){
      if (window.confirm()){
        spokesAPI.disengageKillswitch(server.shortName).then(() => {
          forceUpdate();
          alert("Please allow up to 30 seconds for changes to be reflected.");
        })
      }
    }
  }

  const handleSnackbarClose = () => {
    setCreateSuccessSnackBar(false);
    setEditSuccessSnackBar(false);
    setErrorSnackBar(false);
  };

  // syncEnable/Disable confirmation alert
  const toggleLocationSync = (server, val) => {
    const boolVal = val === 'enable' ? true : false;
    console.log("server: ", server)
    if (window.confirm(`Are you sure you want to ${val} syncing at ${server.shortName}?`)){
      setButtonLoading({...buttonLoading, syncButton: server.siteID});
      dispatch(updateItemStatus(server.siteID));
      spokesAPI.toggleSyncEnable(server.siteID, boolVal).then(() => {
        setButtonLoading({...buttonLoading, syncButton: false});
        forceUpdate()
        setEditSuccessSnackBar(true)
      });
    }}

  const addEditServerModalFields = (
      <>
        <TextField
            {...register("siteName", {required: true})}
            name="siteName"
            sx={{margin: '5px'}}
            label="Client Host Name"
            required={true}
            error={(errors?.siteName) ? true : false}
            helperText={(errors?.siteName) ? "Please enter a host name" : ""}
        />
        <TextField
            {...register("shortName", {required: true})}
            name="shortName"
            sx={{margin: '5px'}}
            label="Location Short Name"
            required={true}
            error={(errors?.shortName) ? true : false}
            helperText={(errors?.shortName) ? "Please enter a short name" : ""}
        />
        <FormControlLabel
            label="Archive"
            control={
              <Switch
                  {...register('archived')}
                  sx={{margin: "5px"}}
              />}>
        </FormControlLabel>
      </>
  )

  const openEditModal = (data) => {
    setEditData(data)
    setEditID(data.siteID)
    setEditModalVisible(true);
  };

  const submitNewSpoke = async (data) =>{
    const newSpoke  = await spokesAPI.createNewSpoke(data);
    if (newSpoke.success === true){
      reset();
      setAddModalVisible(false);
      setCreateSuccessSnackBar(true)
      getServerData();
      getArchivedLocations();
    } else {
      setAddModalVisible(false);
      setErrorSnackBar(true)
    }
  }

  const submitSpokeEdit = async (data) =>{
    const submissionData = {...data, location_id: editID}
    const editedSpoke = await spokesAPI.editSpoke(submissionData);
    if (Object.keys(editedSpoke).length > 1){
      reset();
      setEditModalVisible(false)
      getServerData();
      getArchivedLocations();
    } else {
      setEditModalVisible(false)
      setErrorSnackBar(true)
    }
  }

  const handleTabChange = (event, val) => {
    setActiveTab(val)
  }

  useEffect(() => {
    getServerData();
    getArchivedLocations();
  }, []);

  useEffect(() => {
    const fields = ["siteName", "shortName"]
    if(editID !== 0){
      fields.forEach((field) =>{
        setValue(field, editData[field])
      })
    }
    if (editID === 0){
      fields.forEach((field) =>{
        setValue(field, "")
      })
    }
  }, [editID])

  return (
      <Box sx={{ marginTop: {xs: "8vh", md: "4vh"}}}>
        <Card sx={{padding: "8px", color: "#fff"}}>
          <CardHeader title={"Server Monitor"}/>
          <Box sx={{display: "flex", flexDirection: "row", marginTop: "8px", marginBottom: "8px"}}>
            <Button
                disabled={!userSessionPermissions.CLOS}
                variant={"contained"}
                sx={{backgroundColor: "#212121", color: "#fff", fontSize: "12px"}}
                onClick={() => setAddModalVisible(true)}
            >
              <PlusCircle height={12} /> Add Server
            </Button>
          </Box>
          {ClientData && ClientData.length ? (
              <>
                <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    textColor="inherit"
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: "#fff"
                      }
                    }}
                    sx={{marginLeft: "8px"}}
                >
                  <Tab label="Active" value={0}/>
                  <Tab label="Archived" value={1}/>
                </Tabs>
                <Box>
                  { activeTab === 0 ?
                      <MUIDataTable
                          data={locations}
                          columns={activeServerColumns}
                          options={{
                            filterType: "textField",
                            selectableRows: "none",
                            responsive: "standard",
                            print: false,
                            download: false
                          }}
                      />
                      :
                      <MUIDataTable
                          data={archivedLocations}
                          columns={archivedServerColumns}
                          options={{
                            filterType: "textField",
                            selectableRows: "none",
                            responsive: "standard",
                            print: false,
                            download: false
                          }}
                      />
                  }
                </Box>

              </>
          ) : (
            <div
              style={{ height: "80vh", width: "100%" }}
            >
              <CenteredSpinner />
            </div>
          )}
        </Card>
        <Modal open={editModalVisible} onClose={() => setEditModalVisible(false)}>
          <Box sx={localModalStyle}>
            <Typography variant="h4">Edit Server</Typography>
            <FormGroup>
              {addEditServerModalFields}
              <Box sx={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                <Button
                    type="submit"
                    style={{color: "#fff", backgroundColor: "#2e2e2e", margin: "5px"}}
                    onClick={handleSubmit(submitSpokeEdit)}
                >
                  Submit
                </Button>
                <Button
                    onClick={() => {
                      setEditID(0)
                      setEditModalVisible(false)}
                    }
                    style={{color: "#fff"}}
                >
                  Cancel
                </Button>
              </Box>
            </FormGroup>
          </Box>
        </Modal>
        <Modal open={addModalVisible} onClose={() => setAddModalVisible(false)}>
          <Box sx={localModalStyle}>
            <Typography variant="h4">Add Server</Typography>
            <FormGroup>
              {addEditServerModalFields}
              <Box sx={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                <Button
                    type="submit"
                    style={{color: "#fff", backgroundColor: "#2e2e2e", margin: "5px"}}
                    onClick={handleSubmit(submitNewSpoke)}
                >
                  Submit
                </Button>
                <Button
                    onClick={() => {
                      setAddModalVisible(false)}
                    }
                    style={{color: "#fff"}}
                >
                  Cancel
                </Button>
              </Box>
            </FormGroup>
          </Box>
        </Modal>
        <Snackbar
            open={createSuccessSnackBar}
            autoHideDuration={5000}
            onClose={handleSnackbarClose}
        >
          <Alert
              onClose={handleSnackbarClose}
              severity={"success"}
              variant={"filled"}
              sx={{width: "100%"}}
          >
            Server Created Successfully!
          </Alert>
        </Snackbar>
        <Snackbar
            open={editSuccessSnackBar}
            autoHideDuration={5000}
            onClose={handleSnackbarClose}
        >
          <Alert
              onClose={handleSnackbarClose}
              severity={"success"}
              variant={"filled"}
              sx={{width: "100%"}}
          >
            Server Modified Successfully!
          </Alert>
        </Snackbar>
        <Snackbar
            open={errorSnackBar}
            autoHideDuration={5000}
            onClose={handleSnackbarClose}
        >
          <Alert
              onClose={handleSnackbarClose}
              severity={"error"}
              variant={"filled"}
              sx={{width: "100%"}}
          >
            Server Error - Please Try Again
          </Alert>
        </Snackbar>
      </Box>
  );
};

export default ServerMonitor;