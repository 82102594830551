import React from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import styles from "./activeDupsTable.module.css";

import {
  fc_atoms
} from '../../uiSrc';

export default ({
  columns,
  data,
  pageIndexProp,
  pageSizeProp,
  setPageSizeProp,
  setPageIndexProp,
  setSortObjProp,
  sortObjProp,
  footer,
}) => {

  const {
    Button,
  } = fc_atoms;

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: pageIndexProp ?? 0,
        pageSize: pageSizeProp ?? 20,
        sortBy: [
          {
            id: sortObjProp && sortObjProp.Header ? sortObjProp.Header : 'short_name',
            desc: sortObjProp && sortObjProp.isSortedDesc ? true : false,
          },
        ],
      },
    },
    useSortBy,
    usePagination
  );
  // Render the UI for your table
  return (
    <div
      style={{
        height: "calc(100vh - 270px)",
        maxWidth: "100%",
      }}
      className={styles.table_container}
    >
      <table
        {...getTableProps()}
        style={{ minWidth: "100%" }}
        className={styles.table}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.length &&
                headerGroup.headers.map((column) => {
                  return (
                    <th
                      style={{ textAlign: "left" }}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <div
                        style={column.headerStyle ? column.headerStyle : { width: "100%", height: "100%" }}
                        onClick={() => {
                          const sortHandlerRedux = { Header: column.Header };
                          if (column.isSorted) {
                            if (column.isSortedDesc) {
                              sortHandlerRedux.isSorted = false;
                              sortHandlerRedux.isSortedDesc = false;
                            } else {
                              sortHandlerRedux.isSorted = true;
                              sortHandlerRedux.isSortedDesc = true;
                            }
                          } else {
                            sortHandlerRedux.isSorted = true;
                            sortHandlerRedux.isSortedDesc = false;
                          }

                          setSortObjProp && setSortObjProp(sortHandlerRedux);
                        }}
                      >
                        {column.isSorted
                          ? column.isSortedDesc
                            ? "↓"
                            : "↑"
                          : ""}
                        {column.customHeader
                          ? column.customHeader
                          : column.render("Header")}
                      </div>
                    </th>
                  );
                })}
            </tr>
          ))}
        </thead>
        <tbody>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
          {
              footer ? footer :
                  <div
                      className="inline_center"
                      style={{
                          width: "170%",
                          height: 60,
                          background: "var(--darker)",
                          display: "flex",
                          justifyContent: "flex-end",
                      }}
                  >
                      <div className="inline_center">
                          <Button
                              style={{marginRight: 10}}
                              onClick={() => {
                                  setPageIndexProp && setPageIndexProp(0);
                                  gotoPage(0);
                              }}
                              disabled={!canPreviousPage}
                          >
                              {"First"}
                          </Button>{" "}
                          <Button
                              style={{marginRight: 20}}
                              onClick={() => {
                                  previousPage();
                                  setPageIndexProp && setPageIndexProp(pageIndex - 1);
                              }}
                              disabled={!canPreviousPage}
                          >
                              {"Previous"}
                          </Button>
                          <span>
                <strong>{pageIndex + 1}</strong>
                              &nbsp;&nbsp;
                              <span style={{fontWeight: 400, opacity: 0.6, fontSize: 14}}>
                  of
                </span>
                <span style={{opacity: 0.7}}>
                  &nbsp;&nbsp;
                    {pageOptions.length}
                </span>
              </span>
                          <Button
                              style={{marginRight: 10, marginLeft: 20}}
                              onClick={() => {
                                  nextPage();
                                  setPageIndexProp && setPageIndexProp(pageIndex + 1);
                              }}
                              disabled={!canNextPage}
                          >
                              {"Next"}
                          </Button>{" "}
                          <Button
                              style={{marginRight: 40}}
                              onClick={() => {
                                  setPageIndexProp && setPageIndexProp(pageCount - 1);
                                  gotoPage(pageCount - 1);
                              }}
                              disabled={!canNextPage}
                          >
                              {"Last"}
                          </Button>{" "}
                          <select
                              value={pageSizeProp}
                              onChange={(e) => {
                                  setPageSizeProp && setPageSizeProp(Number(e.target.value));
                                  setPageSize(Number(e.target.value));
                              }}
                          >
                              {[10, 20, 30, 40, 50, 100, 500, 1000].map((pageSize) => (
                                  <option key={pageSize} value={pageSize}>
                                      Show {pageSize}
                                  </option>
                              ))}
                          </select>
                      </div>
                  </div>
          }
      </table>

    </div>
  );
};
