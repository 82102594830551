import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Alert,
  Box,
  Button,
  Card,
  CardHeader,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  Snackbar,
  Typography
} from "@mui/material"
import MUIDataTable from "mui-datatables"
import Spinner from "../../atoms/spinner/spinner";

import BitMgmt from "../../utils/fetch/bitmgmt/bitmgmt";

import {
  setActivePage
} from '../../reducers/appSettingsSlice';

import {
  toggleSyncLocation,
  setViewLocation,
  updateMatchCriteria,
} from '../../reducers/activeDuplicatesSlice';

import FCSpokesAPI from "../../utils/fetch/spokes/spokes";

const modifiedModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: '#333333',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  color: "#fff",
  zIndex: 100,
}

const ActiveDuplicates = ( ) => {

  const dispatch = useDispatch();
  const bitMgmtAPI = new BitMgmt();
  const spokesAPI = new FCSpokesAPI();

  //all locations available to sync
  const locationList = useSelector((state) => state.ActiveDuplicates.locationList);

  //locations selected to sync
  const syncLocations = useSelector((state) => state.ActiveDuplicates.accountSync.locations);

  // active page for the whole application
  const ActivePage = useSelector((state) => state.ActivePage);
  const matchToggle = useSelector(state => state.ActiveDuplicates.matchToggle);

  //pull the initial list of sync enabled locations
  useEffect(() => {
    if (ActivePage !== "active_duplicates") {
      dispatch(setActivePage("active_duplicates"));
    }
    if (!locationList.length) {
      setAllLocationsList();
    }
  },[]);

  /* locations state*/
  const [syncableLocations, setSyncableLocations] = useState([]);
  const [locationsLoading, setLocationsLoading] = useState(true);
  const [activeLocation, setActiveLocation] = useState('')
  const [locationDetail, setLocationDetail] = useState([]);
  const [locationDetailLoading, setLocationDetailLoading] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [syncRunning, setSyncRunning] = useState(false)

  /* match criteria state */
  const [modal,setModal] = useState(false);
  const [matchCriteria, setMatchCriteria] = useState('both')

  /* snackbar/feedback state */
  const [successSnackbar, setSuccessSnackbar] = useState(false)
  const [successSnackbarText, setSuccessSnackbarText] = useState("")
  const [errorSnackbar, setErrorSnackbar] = useState(false)
  const [errorSnackbarText, setErrorSnackbarText] = useState("")

  const locationsTableColumns = [
    {name: "short_name", label: "Location Name", options: {sort: true, filter: true, sortThirdClickReset: true}},
    {name: "sync_enabled", label: "Syncing", options: {
      sort: true,
      filter: true,
      sortThirdClickReset: true,
      customBodyRenderLite: (dataIndex) => {
        const locationRecord = syncableLocations[dataIndex]
        const opposingText = (syncableLocations[dataIndex].sync_enabled === true) ? "Disable" : "Enable"
        return (
            <Button
                variant={"contained"}
                sx={{backgroundColor: (opposingText === "Enable") ? "#212121" : "#CA111E", color: "#fff", fontSize: "12px"}}
                onClick={(event) => {
                  /* Prevent onRowClick function that aggregates dupes from firing */
                  event.stopPropagation()
                  toggleLocationSync(locationRecord, opposingText.toLowerCase())
                }}
            >
              {opposingText} Sync
            </Button>
        )
      }
    }},
    {name: "force_sync", label: "Force Sync", options: {
      sort: false,
      filter: false,
      customBodyRenderLite: (dataIndex) => {
        if  (selectedRows.includes(dataIndex) && syncRunning) {
            return (
                <Button variant={"contained"} sx={{fontSize: "12px"}}>
                  <Spinner /> Syncing...
               </Button>
            )
          } else {
            return (
              <Button
                  variant={"contained"}
                  sx={{fontSize: "12px", backgroundColor: "#212121"}}
                  onClick={(event) => {
                    /* Prevent onRowClick function that aggregates dupes from firing */
                    event.stopPropagation()
                    syncOneLocation(syncableLocations[dataIndex], true, dataIndex)
                  }}
              >
                Force Sync
              </Button>
        )}
      }
    }}
  ]

  const locationDetailTableColumns = [
    {name: "clt_id", label: "Portfolio", options: {sort: true, sortThirdClickReset: true}},
    {name: "port_dups", label: "Active Dupes", options: {sort: true, sortThirdClickReset: true}},
    {name: "total_count", label: "Count", options: {sort: true, sortThirdClickReset: true}},
    {name: "portfolio", label: "Duplicates %", options: {
      sort: true,
      sortThirdClickReset: true,
      customBodyRenderLite: (dataIndex) => {
        const row = locationDetail[dataIndex]
        return (
            (row.port_dups && row.total_count) ?
            `${((row.port_dups / row.total_count) * 100).toFixed(2)}%` :
            '0%'
        )
      }
    }},
  ]

  const clearSnackbars = () => {
    setSuccessSnackbarText("")
    setErrorSnackbarText("")
    setErrorSnackbar(false)
    setSuccessSnackbar(false)
  }

  const retrieveLocationDupes = async (location_id, location_shortname) => {
    setLocationDetailLoading(true)
    /* if we've already pulled data for another location, remove it*/
    setLocationDetail([]);
    setActiveLocation(location_shortname)

    const postbody = {};

    /*TODO: refactor the activeDuplicatesSlice to simplify the match criteria object so we can nuke this*/
    if((matchToggle.ssn && matchToggle.clt_ref_no) || matchToggle.both){
      postbody.ssnMatch = false;
      postbody.cltRefMatch = false;
    } else {
      postbody.ssnMatch = matchToggle.ssn;
      postbody.cltRefMatch = matchToggle.clt_ref_no;
    }

    const FCDups = await bitMgmtAPI.getPortDupCount(location_shortname, postbody.ssnMatch, postbody.cltRefMatch);

    const spokes = await bitMgmtAPI.getSpokePortCountsByLocation(location_shortname);

    const reducedData = FCDups.reduce((prev,curr) => {
      const tmp = {...prev};
      tmp[curr.clt_id] = tmp[curr.clt_id] ? tmp[curr.clt_id].external_dups : []
      tmp[curr.clt_id].push(curr);
      return tmp;
    }, {});

    if (spokes !== false){
      const portWithDupsMap = spokes.map((i) => {
        i.port_dups = reducedData[i.clt_id] ? reducedData[i.clt_id][0].external_dups : 0;
        return i;
      });

      dispatch(setViewLocation({location_id: location_id, dupes: portWithDupsMap}))
      setLocationDetail(portWithDupsMap)
    } else {
      setErrorSnackbarText("Server Error: Check spoke status and try again")
      setErrorSnackbar(true)
    }

    setLocationDetailLoading(false)
  }

  const toggleLocationSync = (server, val) => {
    const boolVal = val === 'enable' ? true : false;
    if (window.confirm(`Are you sure you want to ${val} syncing at ${server.short_name}?`)){
        spokesAPI.toggleSyncEnable(server.location_id, boolVal).then(() => {
          setSuccessSnackbarText(`Successfully ${val}d syncing at ${server.short_name}`)
          setSuccessSnackbar(true)
          setAllLocationsList()
        });
    }
  }

  const handleMatchChange = (event) => {
    setMatchCriteria(event.target.value)

    /*TODO: refactor the activeDuplicatesSlice to simplify the match criteria object */
    switch (event.target.value) {
      case "both":
        updateMatchCriteria({ssn: false, clt_ref_no: false, both: true, clt_id: matchToggle.clt_id});
        break;
      case "ssn":
        updateMatchCriteria({ssn: true, clt_ref_no: false, both: false, clt_id: matchToggle.clt_id});
        break;
      case "clt_ref_no":
        updateMatchCriteria({ssn: false, clt_ref_no: true, both: false, clt_id: matchToggle.clt_id});
        break;
    }
  }

  const setAllLocationsList = async () => {
    setLocationsLoading(true);
    const allCurrentLocations = await bitMgmtAPI.getAllLocations()
    setSyncableLocations(allCurrentLocations.results)
    setLocationsLoading(false);
  };

  /* THE FOLLOWING COMMENTED CODE IS DEPRECATED AS OF 4/3/25 MR */
  
/*  //deselecting all locations in remote sync list
  const deselectAllLocations = () => {
    const locations = [...locationList];
    locations.forEach((l) => {
      if (syncLocations[l.short_name]) {
        dispatch(toggleSyncLocation({short_name: l.short_name, sync_size: l.sync_size}));
      }
    });
  };

  //selecting all locations in remote sync list
  const selectAllLocations = () => {
    const locations = [...locationList];
    locations.forEach((l) => {
      if (!syncLocations[l.short_name]) {
        dispatch(toggleSyncLocation({short_name: l.short_name, sync_size: l.sync_size}));
      }
    });
  };
*/

/*  const listDetails =
    syncLocations && Object.keys(syncLocations).length > 0 && Object.keys(syncLocations).length === locationList.length
    ? {display: 'Unselect All', action: () => deselectAllLocations(), listLength: Object.keys(syncLocations).length}
      : syncLocations && Object.keys(syncLocations).length
    ? {display: 'Select All', action: () => selectAllLocations(), listLength: Object.keys(syncLocations).length}
      : {display: 'Select All', action: () => selectAllLocations(), listLength: 0};
*/


  const syncMultipleLocations = async () => {
    // set all location buttons belonging to selectedRows as loading
    setSyncRunning(true)
    const locationsToRun = [...selectedRows]

    // run sync for each selected row
    const syncPromises = locationsToRun.map((rowId) => {
      return syncOneLocation(syncableLocations[rowId], false)
          .then(() => {
            const index = locationsToRun.indexOf(rowId)
            if (index !== -1) {
              locationsToRun.splice(index, 1)
              return Promise.resolve().then(() => {
                  setSelectedRows([...locationsToRun])
                  setSuccessSnackbarText(`Completed syncing for ${syncableLocations[rowId].short_name}`)
                  setSuccessSnackbar(true)
                  })
            }
          })
          .catch((err) => {
            const index = locationsToRun.indexOf(rowId)
            if (index !== -1) {
              locationsToRun.splice(index, 1)
             return Promise.resolve().then(() => {
               setSelectedRows([...locationsToRun])
               setErrorSnackbarText(`There was an issue syncing ${syncableLocations[rowId].short_name}`)
               setErrorSnackbar(true)

             })
            }
          })
    })

    try {
      await Promise.all(syncPromises).then(() => {
        if (locationsToRun.length === 0){
          /* 2 second buffer for last success/failure snackbar, then complete operation*/
          setTimeout(() => {
            setSuccessSnackbarText("Sync for multiple locations complete")
            setSuccessSnackbar(true)
          }, 3000)
        }
      })
    } catch (error) {
      console.log("Promise.all failed: ", error)
    } finally {
      setSyncRunning(false)
    }


  }

  const syncOneLocation = async (location, singleton, index) => {
    // dispatch(setLocationSyncing({location: location.short_name, running: true}));
    if (singleton === true){
      console.log("running as singleton")
      setSelectedRows([index])
      setSyncRunning(true)
    }

    /* NOTE: sync_size is hard-coded here since we don't currently have a UI element to change it
    *  and neither are we pulling it in as part of the location data that generates the table. Both of those things
    *  should be fixed before changing the sync size to be passed in.
    * */
    /* TODO: replace hard-coded sync-size with dynamic one once conditions above are met */
    const sync = await bitMgmtAPI.syncAccountRecords(location.short_name, 500)

    if (sync === false){
      setErrorSnackbarText(`There was an error syncing ${location.short_name}`)
      setErrorSnackbar(true)
    } else {
      /* TODO: replace hard-coded sync-size with dynamic one once conditions above are met */
      if (syncLocations[location.short_name]) {
        dispatch(toggleSyncLocation({short_name: location.short_name, sync_size: 500}));
      }
    }

    if (singleton === true){
      setSelectedRows([])
      setSyncRunning(false)
    }
  }

/* THE FOLLOWING CODE IS DEPRECATED AS OF 4/3/25 MR*/
/*  const runAccountsSync = async (loc) => {
    // add loading state and reset the port breakdown
    dispatch(setLocationSyncing({location: loc.short_name, running: true}));
    dispatch(setViewLocation({}));

    // sync the accounts
    await bitMgmtAPI.syncAccountRecords(loc.short_name,loc.sync_size);

    // get the updated counts
    const locCounts = await bitMgmtAPI.getFCcentralAccountsByLocation(matchToggle,loc.short_name);
    dispatch(updateLocationCounts({
      location: loc.short_name,
      fc_count: locCounts.fc_count,
      external_dups: locCounts.external_dups,
    }));

    // remove the location from the syncLocation object
    if (syncLocations[loc.short_name]) {
      dispatch(toggleSyncLocation({short_name: loc.short_name, sync_size: loc.sync_size}));
    }
  };*/

  return(
      <Box sx={{ marginTop: {xs: "8vh", md: "4vh"}}}>
      <Box sx={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridColumnGap: "20px",
        gridRowGap: "20px",
        gridAutoRows: "minmax(min-content, max-content)"
      }}>
        <Card sx={{color: "#fff", padding: "8px"}}>
          <CardHeader title="Active Duplicates" />
        {locationsLoading ?
            <div
                className="inline_center"
                style={{alignItems: 'center'}}
            >
              <Spinner/>
            </div>
          :
          <>
              <Box sx={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
                paddingRight: "5px",
                marginBottom: "5px"
              }}>
                <Button
                    sx={{marginRight: 3}}
                    variant="contained"
                    onClick={() => syncMultipleLocations()}
                    disabled={selectedRows.length === 0 ? true : false}
                >
                  {`Sync All (${selectedRows.length})`}
                </Button>
                <Button
                    variant="contained"
                    onClick={() => setModal('matchCriteria')}
                >
                  Criteria
                </Button>
              </Box>

          <div style={{width: '100%'}}>
            <MUIDataTable
                data={syncableLocations}
                columns={locationsTableColumns}
                options={{
                  filterType: "textField",
                  responsive: "standard",
                  selectableRows: "multiple",
                  print: false,
                  download: false,
                  filter: false,
                  searchAlwaysOpen: true,
                  viewColumns: false,
                  rowsSelected: selectedRows,
                  onRowClick: (rowData, rowMeta) => {
                    if (locationDetailLoading === true) {
                      alert(`Currently pulling data for ${activeLocation}. Please allow operation to complete.`)
                    } else {
                      retrieveLocationDupes(syncableLocations[rowMeta.dataIndex].location_id, rowData[0])
                    }
                  },
                  onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) =>{
                    setSelectedRows(rowsSelected)
                  }
                }}
            />
          </div>
        </>
        }
        </Card>
        <Card sx={{color: "#fff", padding: "8px"}}>
          <CardHeader title={ locationDetailLoading
              ? `Location: ${activeLocation}`
              : activeLocation
                  ? `Location:  ${activeLocation}`
                  : `Select a Location...`
          } />
          <div style={{ width: '100%' }}>
            { locationDetail ?
              <div>
                { 
                locationDetailLoading ?
                  <div
                      style={{ alignSelf: 'center', justifyItems: 'center', paddingTop: 10 }}
                    >
                    <Spinner />
                    <Typography>Pulling data... please allow up to two minutes to load</Typography>
                  </div>
                :
                  <MUIDataTable
                    data={locationDetail}
                    columns={locationDetailTableColumns}
                    options={{
                      print: false,
                      download: false,
                      filter: false,
                      viewColumns: false,
                      search: false,
                      selectableRows: "none"
                    }}
                  />
                }
              </div>
            : locationsLoading
              ? null
            : null
            }
          </div>
        </Card>
      </Box>
        <Modal open={modal} onClose={() => setModal(false)}>
          <Box sx={modifiedModalStyle}>
            <Typography variant="h4" align="center">Change Match Criteria</Typography>
            <RadioGroup
              defaultValue={matchCriteria}
              onChange={handleMatchChange}
            >
                <FormControlLabel
                    label="SSN Only"
                    value="ssn"
                    control={<Radio  />}
                />
                <FormControlLabel
                  label="Client Reference No. Only"
                  value="clt_ref_no"
                  control={<Radio  />}
                />
                <FormControlLabel
                    label="Match Both"
                    value="both"
                    control={<Radio />}
                />
            </RadioGroup>
            <Box sx={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
              <Button
                  variant="contained"
                  onClick={() => {
                    setModal(false)}
                  }
                  style={{color: "#fff"}}
              >
                Save and Close
              </Button>
            </Box>
          </Box>
        </Modal>
        <Snackbar
            open={successSnackbar}
            autoHideDuration={5000}
            onClose={clearSnackbars}
        >
          <Alert
              onClose={clearSnackbars}
              severity={"success"}
              variant={"filled"}
              sx={{width: "100%"}}
          >
            {successSnackbarText}
          </Alert>
        </Snackbar>
        <Snackbar
            open={errorSnackbar}
            autoHideDuration={5000}
            onClose={clearSnackbars}
        >
          <Alert
              onClose={clearSnackbars}
              severity={"error"}
              variant={"filled"}
              sx={{width: "100%"}}
          >
            {errorSnackbarText}
          </Alert>
        </Snackbar>
    </Box>
  );
};

export default ActiveDuplicates;
