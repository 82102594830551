/* eslint-disable class-methods-use-this */

const {REACT_APP_API_URL} = process.env;

export default class FCSpokesAPI {
  async getSpokes() {
    const controller = new AbortController();
    const signal = controller.signal;
    signal.addEventListener("abort", () => null);
    setTimeout(() => controller.abort(), 30000);
    const out = await fetch(`${REACT_APP_API_URL}/spokes/read/getSpokes`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
      signal,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return [];
      })
      .then((resJS) => resJS)
      .catch((e) => {
        console.log(e);
        return false;
      });
    return out;
  }

  async getSpokesAndStatus() {
    const controller = new AbortController();
    const signal = controller.signal;
    signal.addEventListener("abort", () => null);
    setTimeout(() => controller.abort(), 30000);
    const data = await fetch(`${REACT_APP_API_URL}/spokes/read/getSpokesAndStatus`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      signal,
      body: JSON.stringify({}),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else return;
      })
      .then(async (resJS) => {
        if (!resJS) return;
        const initItems = await resJS.map((el, i) => {
          return {
            siteName: el.hostname,
            shortName: el.shortname,
            siteID: el.locationid,
            sybaseStatus: el.sybaseserverstatus,
            mysqlStatus: el.mysqlserverstatus,
            nodeStatus: el.siteapistatus,
            closStatus: el.closserverstatus,
            killSwitchEngaged: el.killswitchstatus,
            syncEnabled: el.syncenabled,
            updating: false,
            lastUpserted: el.last_upserted,
            lastSynced: el.last_sync,
            mins_since_last_sync: el.mins_since_last_sync,
          };
        });
        return initItems;
      })
      .catch((error) => {
        console.log(error)
        return false;
      });
    return data;
  }

  async getArchivedSpokes() {
    const controller = new AbortController();
    const signal = controller.signal;
    signal.addEventListener("abort", () => null);
    setTimeout(() => controller.abort(), 30000);
    const out = await fetch(`${REACT_APP_API_URL}/spokes/read/getArchivedSpokes`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
      signal,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return [];
      })
      .then((resJS) => {
        return resJS;
      })
      .catch((e) => {
        console.log(e);
        return [];
      });
    return out;
  }

  async engageKillswitch(location) {
    console.log('engageKillswitch called')
    const controller = new AbortController();
    const signal = controller.signal;
    signal.addEventListener("abort", () => null);
    setTimeout(() => controller.abort(), 30000);
    const out = await fetch(`${REACT_APP_API_URL}/killSwitchEngage`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({location}),
      signal,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return {};
      })
      .then((resJS) => {
        return resJS;
      })
      .catch((e) => {
        console.log(e);
        return {};
      });
    return out;
  }


  async disengageKillswitch(location) {
    console.log('disengageKillswitch called')
    const controller = new AbortController();
    const signal = controller.signal;
    signal.addEventListener("abort", () => null);
    setTimeout(() => controller.abort(), 30000);
    const out = await fetch(`${REACT_APP_API_URL}/killSwitchOff`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({location}),
      signal,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return {};
      })
      .then((resJS) => {
        return resJS;
      })
      .catch((e) => {
        console.log(e);
        return {};
      });
    return out;
  }

  // toggle sync enabled value
  async toggleSyncEnable(locationId, boolVal) {
    const controller = new AbortController();
    const signal = controller.signal;
    signal.addEventListener("abort", () => null);
    setTimeout(() => controller.abort(), 30000);
    const out = await fetch(`${REACT_APP_API_URL}/fc_spokes_write/toggleSync`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({server: locationId, sync_enabled: boolVal}),
      signal,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return {};
      })
      .then((resJS) => {
        return resJS;
      })
      .catch((e) => {
        console.log(e);
        return {};
      });
    return out;
  }

  async getUserNotificationSettings() {
    const controller = new AbortController();
    const signal = controller.signal;
    signal.addEventListener("abort", () => null);
    setTimeout(() => controller.abort(), 30000);
    const out = await fetch(`${REACT_APP_API_URL}/notifications/read/userNotificationSettings`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
      signal,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        console.log(res);
        return [];
      })
      .then((resJS) => {
        console.log(resJS);
        return resJS;
      })
      .catch((e) => {
        console.log(e);
        return [];
      });
    return out;
  }
  
  async updateNotificationPreferences(spokes,types,digest,removeSpoke,removeType) {
    const controller = new AbortController();
    const signal = controller.signal;
    signal.addEventListener("abort", () => null);
    setTimeout(() => controller.abort(), 30000);
    const postbody = {
      spokes: spokes,
      types: types,
      digest: digest,
      removeSpokes: removeSpoke,
      removeTypes: removeType,
    };
    console.log(postbody);
    const out = await fetch(`${REACT_APP_API_URL}/notifications/write/updateNotificationSettings`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postbody),
      signal,
    })
      .then((res) => {
        if (res) {
          return res.json();
        }
        console.log(res);
        return [];
      })
      .then((resJS) => {
        console.log(resJS);
        return resJS;
      })
      .catch((e) => {
        console.log(e);
        return [];
      });
    return out;
  }

  async createNewSpoke(spoke) {
    const controller = new AbortController();
    const signal = controller.signal;
    signal.addEventListener("abort", () => null);
    setTimeout(() => controller.abort(), 30000);
    const postbody = {
      host_name: spoke.siteName,
      short_name: spoke.shortName,
    };
    const out = await fetch(`${REACT_APP_API_URL}/fc_spokes_write/insertNew`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postbody),
      signal,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return {};
      })
      .then((resJS) => {
        return resJS;
      })
      .catch((e) => {
        console.log(e);
        return {};
      });
    return out;
  }

  async editSpoke(spoke) {
    const controller = new AbortController();
    const signal = controller.signal;
    signal.addEventListener("abort", () => null);
    setTimeout(() => controller.abort(), 30000);
    const postbody = {
      host_name: spoke.siteName,
      short_name: spoke.shortName,
      location_id: spoke.location_id,
      archived: spoke.archived,
    };
    const out = await fetch(`${REACT_APP_API_URL}/fc_spokes_write/updateSpoke`, {
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postbody),
      signal,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return {};
      })
      .then((resJS) => {
        return resJS;
      })
      .catch((e) => {
        console.log(e);
        return {};
      });
    return out;
  }}
